<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="120px">
      <el-form-item label="渠道名称" prop="channelName" :rules="rules">
        <el-input v-model="formData.channelName" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="负责人姓名" prop="chargeName" :rules="rules">
        <el-input v-model="formData.chargeName" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="chargeMobile" :rules="rules">
        <el-input v-model="formData.chargeMobile" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item
        v-if="!$route.params.id"
        label="登录密码"
        prop="password"
        :rules="[
          { required: true, message: '该项不能为空', trigger: 'blur' },
          { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: '密码至少8位且包含字母和数字', trigger: 'blur' }
        ]"
      >
        <el-input v-model="formData.password" type="password" placeholder="密码至少8位且包含字母和数字" size="small"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="formData.remark" type="textarea" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-switch class="switch" v-model="formData.status" active-value="1" inactive-value="2" active-text="启用" inactive-text="禁用"></el-switch>
      </el-form-item> -->

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getChannelDetailAPI, editChannelAPI, addChannelAPI } from './api'
export default {
  name: 'AddOrEdit',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        channelName: '',
        chargeName: '',
        chargeMobile: '',
        remark: '',
        password: ''
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getChannelDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editChannelAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addChannelAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getChannelDetail() {
      this.formData = await getChannelDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-form {
    .el-form-item {
      .el-input,
      .el-textarea {
        width: 500px;
      }
    }
  }
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
